import React, { useContext } from "react"
import { Link } from "gatsby"
import "../styles/contact-us.scss"
import withAppContext from "../contexts/app.context"
import Layout from "../components/layout/layout"
import { ShowAnnouncementContext } from "../contexts/show-announcement.context"

const ThanksContactUsPage = () => {
  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  return (
    <Layout>
      <div
        ves-e2e-test="contact-us/contact-success-section"
        className={
          "success container d-flex flex-column align-items-center" +
          (showAnnouncement ? "success--indent" : "")
        }>
        <p className="success__title">Thanks for contacting us</p>
        <p className="success__description">
          A Volterra team member will be in touch shortly to discuss your
          question or interest.
        </p>
        <p className="success__text success__text--gray">
          In the meantime, you can learn more through our{" "}
          <Link
            aria-label="Resources"
            to={"/resources/center"}
            className="success__link">
            resources
          </Link>{" "}
          and{" "}
          <Link
            aria-label="Blogs"
            to={"/resources/blog"}
            className="success__link">
            blog
          </Link>
          .
        </p>
        <p className="success__text">We look forward to connecting with you!</p>
      </div>
    </Layout>
  )
}

export default withAppContext(ThanksContactUsPage)
